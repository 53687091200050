<template>
  <div class="theme_one_block">
    <div class="theme_one_header_block">
      <div class="theme_one_header_container">
        <div class="theme_one_header_label">
          Финансовая грамотность и как ее повысить?
        </div>
      </div>
    </div>
    <div class="theme_one_main_block">
      <div class="theme_one_main_container">
        <div class="theme_one_content_container">
          <a class="theme_one_content_container_btn_back">
            <div class="theme_one_content_container_btn_text">Назад</div>
          </a>
          <div class="theme_one_plot">
            <div class="theme_one_video_plot_theme_active">
              <div class="theme_one_plot_theme_active_label">Тема 1</div>
              <div class="theme_one_plot_theme_active_inactive_adv">Советы по изучению языка</div>
              <div class="theme_one_plot_theme_active_selected_video">Особенности диалектов в ...</div>
              <div class="theme_one_plot_theme_active_inactive_slide">Произношение отдельных...</div>
              <div class="theme_one_plot_theme_active_inactive_pencil">Упражнения</div>
            </div>
            <a href="#" class="theme_one_plot_theme">
              Тема 2
            </a>
            <a href="#" class="theme_one_plot_theme">
              Тема 3
            </a>
            <a href="#" class="theme_one_plot_theme">
              Тема 4
            </a>
            <a href="#" class="theme_one_plot_theme">
              Тестирование
            </a>
          </div>
        </div>
        <div class="theme_one_video_desc">
          <div class="theme_one_desc_text_container">
            <div class="theme_one_desc_title">
              <div class="theme_one_desc_title_text">Особенности диалектов в английском</div>
              <div class="theme_one_desc_title_time">10 минут</div>
            </div>
            <iframe src="https://www.youtube.com/embed/1tnj3UCkuxU"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer;
                    autoplay;
                    clipboard-write;
                    encrypted-media;
                    gyroscope;
                    picture-in-picture"
                    allowfullscreen
                    class="theme_one_desc_video"
            >

            </iframe>
            <div class="theme_one_desc_download">
              Скачать
            </div>
            <div class="theme_one_desc_btn">
              <a href="#" class="theme_one_desc_btn_back">Назад</a>
              <a href="#" class="theme_one_desc_btn_next">Далее</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThemeOne"
}
</script>

<style scoped>

</style>